import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { has } from "lodash";
import Blurb from "./Blurb";
import Icons from "./Icons";
import Title from "./Title";
import Section from "./Section";
import styles from "./ShopCategoryTemplate.module.css";
import Constants from "../style/Constants";

export default function ShopCategoryTemplate(props) {
  const {
    shareInfo,
    blurb,
    title,
    slug,
    collectionId,
    outOfStockMessage,
  } = props.data.shop;

  const loadClasses = (container, hasProducts) => {
    container.classList.remove(styles.shopifyPreload);
    if (hasProducts) {
      container.classList.add(styles.shopifyLoaded);
    }
  };

  const [productCount, setProductCount] = useState(-1);

  useEffect(() => {
    const container = document.getElementById("shopifyContainer");
    if (!collectionId) {
      loadClasses(container, 0);
      return;
    }
    window.shopify.createComponent("collection", {
      id: collectionId,
      node: container,
      options: {
        productSet: {
          styles: {
            paginationButton: {
              "background-color": "black"
            }
          },
          iframe: true,
          events: {
            afterRender(component) {
              if (!component.model || !component.model.products) {
                loadClasses(container, 0);
                setProductCount(0);
              } else {
                const productAmt = component.model.products.length;
                loadClasses(container, productAmt);
                setProductCount(productAmt);
              }
            },
          },
        },
        lineItem: {
          styles: {
            quantity: {
              "margin-right": "0",
            },
          },
        },
        toggle: {
          styles: {
            toggle: {
              "background-color": Constants.COPPER,
              ":hover": {
                "background-color": Constants.COPPER,
              },
            },
          },
        },
        product: {
          text: { button: "Learn More" },
          buttonDestination: 'modal',
          styles: {
            product: {
              "margin-bottom": "2.5rem",
            },
            title: {
              "margin-bottom": "0.5rem",
            },
            prices: {
              "margin-bottom": "0.5rem",
            },
            buttonWrapper: {
              "margin-top": "0.75rem",
            },
            button: {
              "background-color": Constants.COPPER,
              ":hover": {
                "background-color": Constants.COPPER,
              },
              ":focus": {
                "background-color": Constants.COPPER,
              }
            },
          },
        },
        cart: {
          popup: false,
          styles: {
            button: {
              "background-color": Constants.COPPER,
              ":hover": {
                "background-color": Constants.COPPER,
              },
            },
          },
        },
      },
    });
  }, [collectionId]);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={`Lunsford Ridge Farm | ${title}`} />
        <meta
          property="og:url"
          content={`https://www.lunsfordridgefarm.com/shop/${slug}`}
        />
      </Helmet>
      {shareInfo && shareInfo.image && shareInfo.description && (
        <Helmet>
          <meta name="description" content={shareInfo.description} />
          <meta property="og:description" content={shareInfo.description} />
          <meta
            property="og:image"
            content={`https://www.lunsfordridgefarm.com${shareInfo.image.childImageSharp.fixed.src}`}
          />
        </Helmet>
      )}
      <Section>
        <Title>{title}</Title>
        <Blurb blurb={blurb} />
      </Section>
      <Section
        id="shopifyContainer"
        extraClasses={[styles.shopify, styles.shopifyPreload]}
      >
        {(!collectionId || !productCount) && (
          <div className={styles.outofstock}>{outOfStockMessage}</div>
        )}
      </Section>
      <Section>
        <Icons current={slug} />
      </Section>
    </>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    shop: shopJson(id: { eq: $id }) {
      id
      collectionId
      outOfStockMessage
      slug
      title
      ...OpenGraphFragmentShop
      ...BlurbFragmentShop
    }
  }
`;
